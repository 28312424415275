import { GameType } from '../../../types/game';

type Payload = {
  effort?: string,
  risk?: string,
  complexity?: string,
  completionTime?: string,
  definition?: string,
  boldDefinition?: string
}
export interface CardConfig {
  value: number;
  displayValue: string;
  color: string;
  payload ?: Payload
}
export const fibonacciCards: CardConfig[] = [
  { value: 0, displayValue: '0.5', color: '#b3ffb3', payload: {effort: "Minimal", risk: "None", complexity: "Minimal", completionTime: "A few minutes at most"} },
  { value: 1, displayValue: '1', color: '#A3DFF2', payload: {effort: "Minimum", risk: "Small", complexity: "Very easy", completionTime: "Less than 1 hour", definition: "The team understands the requirements and consider it relatively easy.", boldDefinition: "Most likely the smallest item in the sprint."}  },
  { value: 2, displayValue: '2', color: '#A3DFF2', payload: {effort: "Small", risk: "Small", complexity: "Easy", completionTime: "About half a day", definition: "The team have confidence in the requirements and no research is required."}  },
  { value: 3, displayValue: '5', color: '#9EC8FE', payload: {effort: "Average", risk: "Small", complexity: "Medium", completionTime: "Less than a day", definition: "The team have done this a lot. They know what needs to be done. There are a few extra steps, but that's it."}  },
  { value: 5, displayValue: '8', color: '#9EC8FE', payload: {effort: "Large", risk: "Small", complexity: "Hard", completionTime: "A few days", definition: "This is complex work or the team don't do this often. Most developers will require assistance from someone else on the team.", boldDefinition: "Most likely the largest item in the sprint."}  },
  { value: 13, displayValue: '13', color: '#f8e9ba', payload: {effort: "Large", risk: "Medium", complexity: "Hard", completionTime: "More than a week"}  },
  { value: 21, displayValue: '21', color: '#F4DD94', payload: {effort: "Maximum", risk: "Large", complexity: "Hard", completionTime: "More than 2 weeks", definition: "This is a complexe piece of work with a lot of unknowns and required multiple assumptions to size. It is too much to complete in one sprint.", boldDefinition:"The team might have to reach out to external help to understand the requirements."}  },
  { value: -2, displayValue: '❓', color: '#ff9999', payload: {effort: "Maximum", risk: "Large", complexity: "Hard", completionTime: "Unknown", definition: "This story is very complexe or unclear and cannot be completed within a sprint. More analysis is required to be ble to assess it correctly.", boldDefinition:"The team might have to reach out to external help to understand the requirements."}  },
];

export const shortFibonacciCards: CardConfig[] = [
  { value: 0, displayValue: '0', color: 'var(--color-background-secondary)' },
  { value: 0.5, displayValue: '½', color: '#9EC8FE' },
  { value: 1, displayValue: '1', color: '#9EC8FE' },
  { value: 2, displayValue: '2', color: '#9EC8FE' },
  { value: 3, displayValue: '3', color: '#A3DFF2' },
  { value: 5, displayValue: '5', color: '#A3DFF2' },
  { value: 8, displayValue: '8', color: '#9DD49A' },
  { value: 13, displayValue: '13', color: '#9DD49A' },
  { value: 21, displayValue: '20', color: '#F4DD94' },
  { value: 34, displayValue: '40', color: '#F4DD94' },
  { value: 55, displayValue: '100', color: '#F39893' },
  { value: -2, displayValue: '❓', color: 'var(--color-background-secondary)' },
];

export const tShirtCards: CardConfig[] = [
  { value: 10, displayValue: 'XXS', color: 'var(--color-background-secondary)' },
  { value: 20, displayValue: 'XS', color: '#9EC8FE' },
  { value: 30, displayValue: 'S', color: '#9EC8FE' },
  { value: 40, displayValue: 'M', color: '#A3DFF2' },
  { value: 50, displayValue: 'L', color: '#A3DFF2' },
  { value: 60, displayValue: 'XL', color: '#9DD49A' },
  { value: 70, displayValue: 'XXL', color: '#9DD49A' },
  { value: -2, displayValue: '❓', color: 'var(--color-background-secondary)' },
];

export const tShirtAndNumbersCards: CardConfig[] = [
  { value: 10, displayValue: 'S', color: '#9EC8FE' },
  { value: 20, displayValue: 'M', color: '#9EC8FE' },
  { value: 30, displayValue: 'L', color: '#A3DFF2' },
  { value: 40, displayValue: 'XL', color: '#A3DFF2' },
  { value: 50, displayValue: '1', color: '#9DD49A' },
  { value: 60, displayValue: '2', color: '#9DD49A' },
  { value: 70, displayValue: '3', color: '#F4DD94' },
  { value: 80, displayValue: '4', color: '#F4DD94' },
  { value: 90, displayValue: '5', color: '#F39893' },
];

export const getCards = (gameType: GameType | undefined): CardConfig[] => {
  switch (gameType) {
    case GameType.Fibonacci:
      return fibonacciCards;
    case GameType.ShortFibonacci:
      return shortFibonacciCards;
    case GameType.TShirt:
      return tShirtCards;
    case GameType.TShirtAndNumber:
      return tShirtAndNumbersCards;
    default:
      return fibonacciCards;
  }
};

export const getRandomEmoji = () => {
  const emojis = [
    '☕',
    '🥤',
    '🍹',
    '🍸',
    '🍧',
    '🍨',
    '🍩',
    '🍎',
    '🧁',
    '🍪',
    '🍿',
    '🌮',
    '🍦',
    '🍉',
    '🍐',
    '🍰',
    '🍫',
  ];
  return emojis[Math.floor(Math.random() * emojis.length)];
};

export const getCustomCards = (values: string[]) => {
  const customCards: CardConfig[] = [
    { value: 0, displayValue: values[0], color: 'var(--color-background-secondary)' },
    { value: 1, displayValue: values[1], color: '#9EC8FE' },
    { value: 2, displayValue: values[2], color: '#9EC8FE' },
    { value: 3, displayValue: values[3], color: '#A3DFF2' },
    { value: 4, displayValue: values[4], color: '#A3DFF2' },
    { value: 5, displayValue: values[5], color: '#9DD49A' },
    { value: 6, displayValue: values[6], color: '#9DD49A' },
    { value: 7, displayValue: values[7], color: '#F4DD94' },
    { value: 8, displayValue: values[8], color: '#F4DD94' },
    { value: 9, displayValue: values[9], color: '#F39893' },
    { value: -2, displayValue: '❓', color: 'var(--color-background-secondary)' }
  ];
  return customCards.filter(
    (card) => card.displayValue !== undefined && card.displayValue.trim() !== '',
  );
};
