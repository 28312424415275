import { PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
    auth: {
    clientId: "537f2900-86c3-4375-b643-07019fcb7848",
    authority: "https://login.microsoftonline.com/af6b39e0-2460-4372-9f07-27d3ebfaf1f1", // or use 'common' for multi-tenant apps
    redirectUri: "/login", // replace with your redirect URI

    },

  };
  
  export const loginRequest = {
    scopes: ["User.Read"], // Define the Microsoft Graph API scopes you need
  };

  export const msalInstance = new PublicClientApplication(msalConfig);