import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Game } from '../types/game';
import { Player } from '../types/player';
const firebaseConfig = {
  apiKey: "AIzaSyCRSthkHbahq183QCiCrzJlbqxaEdi0K74",
  authDomain: "poker-planning-433019.firebaseapp.com",
  projectId: "poker-planning-433019",
  storageBucket: "poker-planning-433019.appspot.com",
  messagingSenderId: "655065038834",
  appId: "1:655065038834:web:bbacbcbede6c46d0a1f56b"
};



// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const gamesCollectionName = 'games';
const playersCollectionName = 'players';
const db = firebase.firestore();

/**
 * Adds a game to the Firestore database.
 *
 * @param {string} gameId - The ID of the game to be added.
 * @param {any} data - The data associated with the game.
 * @returns {Promise<boolean>} A promise that resolves to `true` when the game is successfully added.
 */
export const addGameToStore = async (gameId: string, data: any) => {

  console.log('firebaseConfig', firebaseConfig);

  const res = await db.collection(gamesCollectionName).doc(gameId).set(data);

  console.log('res', res);
  return true;
};

/**
 * Retrieves a game from the Firestore database by its ID.
 *
 * @param {string} id - The ID of the game to retrieve.
 * @returns {Promise<Game | undefined>} A promise that resolves to the game data or `undefined` if the game does not exist.
 */
export const getGameFromStore = async (id: string): Promise<Game | undefined> => {
  const response = db.collection(gamesCollectionName).doc(id);
  const result = await response.get();
  let game = undefined;
  if (result.exists) {
    game = result.data();
  }
  return game as Game;
};

/**
 * Retrieves the list of players associated with a specific game from the Firestore database.
 *
 * @param {string} gameId - The ID of the game.
 * @returns {Promise<Player[]>} A promise that resolves to an array of `Player` objects.
 */
export const getPlayersFromStore = async (gameId: string): Promise<Player[]> => {
  const db = firebase.firestore();
  const response = db.collection(gamesCollectionName).doc(gameId).collection(playersCollectionName);
  const results = await response.get();
  let players: Player[] = [];
  results.forEach((result) => players.push(result.data() as Player));
  return players;
};

/**
 * Retrieves a specific player associated with a game from the Firestore database.
 *
 * @param {string} gameId - The ID of the game.
 * @param {string} playerId - The ID of the player to retrieve.
 * @returns {Promise<Player | undefined>} A promise that resolves to the player data or `undefined` if the player does not exist.
 */
export const getPlayerFromStore = async (gameId: string, playerId: string): Promise<Player | undefined> => {
  const db = firebase.firestore();
  const response = db.collection(gamesCollectionName).doc(gameId).collection(playersCollectionName).doc(playerId);
  const result = await response.get();
  let player = undefined;
  if (result.exists) {
    player = result.data();
  }
  return player as Player;
};

/**
 * Streams real-time updates for a specific game's data from the Firestore database.
 *
 * @param {string} id - The ID of the game.
 * @returns {firebase.firestore.DocumentReference} A Firestore document reference to the game.
 */
export const streamData = (id: string) => {
  return db.collection(gamesCollectionName).doc(id);
};

/**
 * Streams real-time updates for the players of a specific game from the Firestore database.
 *
 * @param {string} id - The ID of the game.
 * @returns {firebase.firestore.CollectionReference} A Firestore collection reference to the players in the game.
 */
export const streamPlayersFromStore = (id: string) => {
  return db.collection(gamesCollectionName).doc(id).collection(playersCollectionName);
};

/**
 * Updates a game's data in the Firestore database.
 *
 * @param {string} gameId - The ID of the game to update.
 * @param {any} data - The new data to update the game with.
 * @returns {Promise<boolean>} A promise that resolves to `true` when the game is successfully updated.
 */
export const updateGameDataInStore = async (gameId: string, data: any): Promise<boolean> => {
  const db = firebase.firestore();
  await db.collection(gamesCollectionName).doc(gameId).update(data);
  return true;
};

/**
 * Adds a player to a specific game in the Firestore database.
 *
 * @param {string} gameId - The ID of the game.
 * @param {Player} player - The player data to be added.
 * @returns {Promise<boolean>} A promise that resolves to `true` when the player is successfully added.
 */
export const addPlayerToGameInStore = async (gameId: string, player: Player) => {
  await db.collection(gamesCollectionName).doc(gameId).collection(playersCollectionName).doc(player.id).set(player);
  return true;
};

/**
 * Removes a player from a specific game in the Firestore database.
 *
 * @param {string} gameId - The ID of the game.
 * @param {string} playerId - The ID of the player to remove.
 * @returns {Promise<boolean>} A promise that resolves to `true` when the player is successfully removed.
 */
export const removePlayerFromGameInStore = async (gameId: string, playerId: string) => {
  await db.collection(gamesCollectionName).doc(gameId).collection(playersCollectionName).doc(playerId).delete();
  return true;
};

/**
 * Updates a player's data in a specific game in the Firestore database.
 *
 * @param {string} gameId - The ID of the game.
 * @param {Player} player - The player data to update.
 * @returns {Promise<boolean>} A promise that resolves to `true` when the player is successfully updated.
 */
export const updatePlayerInStore = async (gameId: string, player: Player) => {
  await db.collection(gamesCollectionName).doc(gameId).collection(playersCollectionName).doc(player.id).update(player);

  return true;
};

/**
 * Removes a game and its associated players from the Firestore database.
 *
 * @param {string} gameId - The ID of the game to remove.
 * @returns {Promise<boolean>} A promise that resolves to `true` when the game and its players are successfully removed.
 */
export const removeGameFromStore = async (gameId: string) => {
  await db.collection(gamesCollectionName).doc(gameId).delete();
  await db
    .collection(gamesCollectionName)
    .doc(gameId)
    .collection(playersCollectionName)
    .get()
    .then((res) => {
      res.forEach((element) => {
        element.ref.delete();
      });
    });
  return true;
};

/**
 * Removes old games from the Firestore database that are older than a specified number of months.
 *
 * @returns {Promise<boolean>} A promise that resolves to `true` when old games are successfully removed.
 */
export const removeOldGameFromStore = async () => {
  const monthsToDelete = 6;
  const dateObj = new Date();
  const requiredDate = new Date(dateObj.setMonth(dateObj.getMonth() - monthsToDelete));
  const games = await db.collection(gamesCollectionName).where('createdAt', '<', requiredDate).get();

  console.log('Games length', games.docs.length);
  if (games.docs.length > 0) {
    const data = games.docs[0].data();
    console.log(data);
    console.log(games.docs[games.docs.length - 1].data());
    console.log(data.createdAt.toDate().toString());
    console.log(games.docs[games.docs.length - 1].data().createdAt.toDate().toString());
    const gamesCollection: any = [];

    games.forEach((game) => {
      gamesCollection.push(game);
    });
    for (let game of gamesCollection) {
      console.log('Deleting:', game.data().name);
      const players = await game.ref.collection(playersCollectionName).get();
      const playersCollection: any = [];
      players.forEach((player: Player) => {
        playersCollection.push(player);
      });
      for (let player of playersCollection) {
        await player.ref.delete();
      }
      await game.ref.delete();
      console.log('deleted');
    }
  }

  return true;
};
