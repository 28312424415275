import { PlayerGame } from '../types/player';

const playerGamesStoreName = 'playerGames';

/**
 * Retrieves the list of player games stored in the local storage.
 * If no games are found in the cache, an empty array is returned.
 *
 * @returns {PlayerGame[]} An array of `PlayerGame` objects retrieved from the local storage.
 */
export const getPlayerGamesFromCache = (): PlayerGame[] => {
  let playerGames: PlayerGame[] = [];

  const store = localStorage.getItem(playerGamesStoreName);
  if (store) {
    playerGames = JSON.parse(store);
  }
  return playerGames;
};

/**
 * Checks if a specific game is present in the player's cached games.
 *
 * @param {string} gameId - The ID of the game to check for in the player's cache.
 * @returns {boolean} `true` if the game is found in the cache, otherwise `false`.
 */
export const isGameInPlayerCache = (gameId: string): boolean => {
  const playerGames = getPlayerGamesFromCache();
  const found = playerGames.find((playerGames) => playerGames.id === gameId);
  if (found) {
    return true;
  }
  return found ? true : false;
};

/**
 * Updates the player's games in the local storage with a new array of `PlayerGame` objects.
 *
 * @param {PlayerGame[]} playerGames - The array of `PlayerGame` objects to store in the cache.
 */
export const updatePlayerGamesInCache = (playerGames: PlayerGame[]) => {
  localStorage.setItem(playerGamesStoreName, JSON.stringify(playerGames));
};
