import React, { useState, useEffect } from "react";
import { Button, Slide, useMediaQuery, } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import AppToolbar from '@material-ui/core/Toolbar';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import MergeTypeOutlinedIcon from '@material-ui/icons/MergeTypeOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import BookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { deepOrange, deepPurple } from '@mui/material/colors';
import Popover from '@mui/material/Popover';
import LogoutIcon from '@mui/icons-material/Logout';
import {ListItemButton, Typography, ListItem, List, ListItemText, ListItemIcon} from "@mui/material";
import { Avatar , Box} from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import './Toolbar.css';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { LanguageControl } from '../LanguageControl/LanguageControl';
export const title = 'HGC Technologies';

export const Toolbar = () => {
  const location = useLocation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement  | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
  const { t } = useTranslation();
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    instance.logoutPopup()
    .then(() => {
      history.push('/login');
    })
    .catch(error => {
      console.error('Logout failed:', error);
    });
  }

  const open = Boolean(anchorEl);
console.log(location)
  useEffect(()=>{
     
    setIsAuthenticated(location.pathname !== "/login"? true:false);
  },[location])

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Slide direction='down' in={true} timeout={800}>
      <AppBar position='sticky' className='AppBar'>
        <AppToolbar>
          <div className='HeaderContainer'>
            {!isSmallScreen &&
              <div className='HeaderLeftContainer' onClick={() => history.push('/')} style={{ display: 'flex', alignItems: 'center' }}>
                <img src="/favicon.svg" alt="Planning Poker Tool" className='HeaderIcon' width="50" height="50" />
                <Typography variant={isSmallScreen ? 'subtitle1' : 'h5'} color='inherit' noWrap>
                  {title}
                </Typography>
              </div>
            }
            <Box sx={{display:"flex", alignItems:"center"}}>
              <Button
                title={t('toolbar.menu.about')}
                startIcon={<InfoOutlinedIcon />}
                color='inherit'
                onClick={() => history.push('/about-planning-poker')}
              >
                {!isSmallScreen ? t('toolbar.menu.about') : null}
              </Button>
              <Button
                title={t('toolbar.menu.guide')}
                startIcon={<SearchOutlinedIcon />}
                color='inherit'
                onClick={() => history.push('/guide')}
              >
                {!isSmallScreen ? t('toolbar.menu.guide') : null}
              </Button>
              <Button
                title={t('toolbar.menu.examples')}
                startIcon={<BookOutlinedIcon />}
                color='inherit'
                onClick={() => history.push('/examples')}
              >
                {!isSmallScreen ? t('toolbar.menu.examples') : null}
              </Button>
              <Button
                title={t('toolbar.menu.newSession')}
                startIcon={<AddCircleOutlineIcon />}
                color='inherit'
                onClick={() => history.push('/')}
                data-testid='toolbar.menu.newSession'
              >
                {!isSmallScreen ? t('toolbar.menu.newSession') : null}
              </Button>
              <Button
                title={t('toolbar.menu.joinSession')}
                startIcon={<MergeTypeOutlinedIcon />}
                size={isSmallScreen ? 'small' : 'large'}
                color='inherit'
                onClick={() => history.push('/join')}
                data-testid='toolbar.menu.joinSession'
              >
                {!isSmallScreen ? t('toolbar.menu.joinSession') : null}
              </Button>
              <Avatar onClick={handleClick} sx={{ bgcolor: deepOrange[500], cursor: 'pointer' }}>{account?.name?.split(' ').map(function(word) {
    return word.charAt(0).toUpperCase();
}).join('')}</Avatar>
 <Popover
        id={"menu-popover"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
         <List>
         <ListItem disablePadding >
          <ListItemButton onClick={handleLogout}>
          <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
          </ListItemButton>
         
          </ListItem>
         </List>
        
      </Popover>
              {/* <LanguageControl /> */}
            </Box>
          </div>
        </AppToolbar>
      </AppBar>
    </Slide>
  );
};
