import { Grid } from '@material-ui/core';
import React from 'react';
import { useMsal } from '@azure/msal-react';
import { JoinGame } from '../../components/Poker/JoinGame/JoinGame';

/**
 * The `JoinPage` component renders the page where users can join an existing game.
 * It retrieves the active user's account information using the Microsoft Authentication Library (MSAL)
 * and passes the account name as a prop to the `JoinGame` component.
 * The page layout is structured using Material-UI's `Grid` system, ensuring that content is centered and spaced appropriately.
 *
 * @returns {JSX.Element} The JSX element representing the "Join Game" page of the application.
 */
export const JoinPage = () => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  return (
    <>
      <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
        <Grid container item sm={12} lg={11} justify='center' alignItems='center' spacing={3}>
          <Grid item sm={12} lg={6}>
            <JoinGame accountName={account?.name} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default JoinPage;
