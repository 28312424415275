import { Divider, Link, Slide, useMediaQuery,Button } from '@material-ui/core';
import './Footer.css';
import { Box,  Typography } from '@mui/material';

export const Footer = () => {
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
  return (
    <footer>
      <Slide in={true} direction='up' timeout={3000}>
        <Box sx={{mt:12, display:"flex", justifyContent:"space-between"}}>

              <Box  sx={{ display: 'flex', alignItems: 'center', px:10, pt:10, pb:5 }}>
                <img src="/favicon.svg" alt="Planning Poker Tool" className='HeaderIcon' width="35" height="35" />
                <Box>
                <Typography  variant={isSmallScreen ? 'subtitle1' : 'subtitle1'}  sx={{fontWeight:"semi-bold",  lineHeight:1.2}} color='inherit' noWrap>
                   Poker Planning
                </Typography>
                <Typography  variant={isSmallScreen ? 'subtitle1' : 'subtitle1'} sx={{fontSize:10, p:0, lineHeight:1.2}} color='inherit' noWrap>
                   @ {new Date().getFullYear()} HGC Technologies Inc. All rights reserved.
                </Typography>
                </Box>
                
              </Box>
              <Box sx={{display:"flex", flexDirection:"column", minWidth:200, alignContent:"center", justifyContent:"center", pt:5}}>
                <Button onClick={()=>window.open("mailto:antoine.arbour@wearehgc.com")}  className='FooterButton' variant='contained' color="primary">Contact Us</Button>
              </Box>
      
        </Box>
      </Slide>
    </footer>
  );
};
